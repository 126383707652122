.button {
 padding: 8px 40px;
  border-radius: 100px;
  font-family: Karla;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #fcfcfc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bigPrimary {
  background-color: #5b7cfe;
  height: 32px;
}

.bigPrimary:hover {
  background-color: #ebefff;
  color: #5b7cfe;
}

.bigSecondary {
  border: 1px solid #5b7cfe;
  color: #ffffff;
  height: 32px;
}

.bigSecondary:hover {
  background-color: #ebefff;
  color: #5b7cfe;
}

.smallPrimary {
  background-color: #5b7cfe;
  color: #fcfcfc;
  font-family: Karla;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border: 1px solid #5b7cfe;
  width: max-content;
}

.smallSecondary {
  color: #5b7cfe;
  border: 1px solid #5b7cfe;
  font-family: Karla;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.disabled {
  color: #adbeff;
  pointer-events: none;
}

@media (max-width: 1200px) {
    .responsiveBtn {
        box-sizing: border-box;
        width: 104px;
        padding: 8px 32px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0;
        text-align: center;
    }
    .responsiveSignUp {
        box-sizing: border-box;
        width: 107px;
        border:none;
        outline: 1px solid #5b7cfe;
    }
}
@media (max-width: 320px) {
    .mobileLight {
        color:#FCFCFC;
        font-family: Karla;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0;
        text-align: center;
        height: 40px;
    }
    .mobileDark {
        font-family: Karla;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0;
        text-align: center;
        color: #1D1E2C;
        height: 40px;
    }
}
