.wrap {
  display: flex;
  flex-direction: column;
  gap: 64px;
  width: 100vw;
}

.darkBlock {
  background: #1d1e2c;
  display: flex;
  padding: 72px 0;
  position: relative;
  box-sizing: border-box;
}

.whiteBlock {
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 1216px;
  margin: 0 auto;
}

.headline {
  width: 1216px;
  transform: translateY(22%);
  animation: ani 1.5s forwards;
}
@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  0% {
    transform: translateY(22%);
  }
  100% {
    transform: translateX(0);
  }
}

.headlineDesc {
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: start;
}

.headlineTitle {
  color: #fcfcfc;
  font-family: Kanit;
  font-size: 61px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px;
  text-transform: uppercase;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.subTitle {
  color: #fff;
  font-family: Kanit;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  opacity: 0.9;
}

.text {
  color: #d4d4d4;
  font-family: Karla;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
  opacity: 0.9;
}

.headlineImage {
  position: absolute;
  right: 0;
  top: 72px;
}

.containerLists {
  display: flex;
  flex-direction: column;
  gap: 120px;
  width: 1216px;
}

.containerList {
  display: flex;
  justify-content: space-between;
}

.cardList {
  width: 488px;
  padding: 40px 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  border-radius: 20px;
  box-shadow: 0 4px 10px 0 rgba(175, 175, 175, 0.25);
  box-sizing: border-box;
  height: max-content;
  text-align: start;
}

.cardListTitle {
  color: #5b7cfe;
  font-family: Kanit;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  padding-left: 20px;
}

.li {
  color: #1d1e2c;
  font-family: Karla;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
}

.li:before {
  margin-left: 0;
}

.smallLi {
  width: 251px;
}

@media (max-width: 1360px) {
  .headlineImage svg {
    width: 600px;
  }
  .container {
    max-width: 90vw;
  }

  .headline {
    width: 90vw;
  }
  .headlineImage {
    top: 50px;
  }
  .containerLists {
    width: 90vw;
  }
  .listImage svg {
    width: 500px;
  }
}

@media (max-width: 1150px) {
  .headlineImage svg {
    width: 500px;
  }
  .headlineImage {
    top: 30px;
  }
  .listImage svg {
    width: 350px;
  }
  .containerLists {
    gap: 40px;
    width: 90vw;
  }
}

@media (max-width: 1000px) {
  .hideImage,
  .hideListImage {
    display: none;
  }

  .bottomContainerList {
    justify-content: end;
  }
}

@media (max-width: 768px) {
  .wrap {
    gap: 56px;
  }
  .darkBlock {
    padding: 56px 0;
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .smallLi {
    width: 220px;
  }
}
@media (max-width: 500px) {
  .headline {
    transform: none;
    animation: none;
  }
  .headlineTitle {
    font-size: 39px;
    line-height: 40px;
  }
  .darkBlock {
    padding:88px 0 40px 0;
  }
}
@media (max-width: 400px) {
  .bottomContainerList,
  .topContainerList {
    justify-content: center;
  }

  .wrap {
    gap: 40px;
  }

  .headlineTitle {
    font-size: 39px;
    line-height: 40px;
  }

  .subTitle {
    font-size: 20px;
    line-height: 24px;
  }

  .text {
    font-size: 16px;
    letter-spacing: 0.08px;
    opacity: 0.9;
  }

  .cardList {
    width: 288px;
    padding: 24px;
  }

  .cardListTitle {
    font-size: 20px;
    line-height: 24px;
  }

  .li {
    font-size: 14px;
    line-height: 16px;
  }
}
