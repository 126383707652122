.card {
  width: 392px;
  height: 300px;
  box-shadow: 0 4px 6px 0 rgba(176, 176, 176, 0.25);
  border: 1px solid #eee;
  border-radius: 20px;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardInfo {
}

.first {
  display: flex;
  gap: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 12px;
}

.avatar {
  width: 56px;
  height: 56px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.info {
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.firstInfo {
  display: flex;
  gap: 4px;
}

.data {
  color: #9c9c9c;
  font-family: Karla;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.06px;
}

.title {
  color: #040404;
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.containerAnswers {
  display: flex;
  gap: 16px;
}

.answers {
  display: flex;
  gap: 6px;
}

.text {
  color: #161616;
  font-family: Karla;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.8;
  text-align: start;
  height: 84px;
}

.third {
  display: flex;
  justify-content: space-between;
}
.link {
  box-sizing: border-box;
  width: 164px;
  height: 32px;
  text-decoration: none;
  padding: 8px 54px;
  border:1px solid #5B7CFE;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  font-family: Karla;
}
.button {
  width: 84px;
  height: 16px;
  text-decoration: none;
}
.hideTitleTablet {
  display: none;
}
.timeBlock {
  display: flex;
  gap: 4px;
}

@media (max-width: 768px) {
  .card {
    width: 320px;
    height: 404px;
  }

  .first {
    padding-top: 16px;
    padding-bottom: 16px;
    align-items: center;
    gap: 24px;
    margin-bottom: 16px;
  }
  .info {
    gap: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .containerAnswers {
    gap: 8px;
  }

  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
  }
  .hideTitle {
    display: none;
  }
  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #e8e8e8;
  }
  .third {
    flex-direction: column;
    gap: 8px;
    width: 100%;
    align-items: center;
  }
  .button,.link {
    box-sizing: border-box;
    width: 272px;
    height: 32px;
  }
  .blackColor {
    color: #1d1e2c;
  }
  .hideTitleTablet {
    display: block;
  }
}
@media (max-width: 450px) {
  .card {
    width: auto;
  }
}



@media (max-width: 375px) {
  .card {
    width: 288px;
    height: 416px;
  }
  .title {
    font-size: 14px;
    padding-bottom: 12px;
  }
  .first {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 16px;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
  }
  .info {
    gap: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .avatar {
    width: 48px;
    height: 48px;
  }
  .button,.link {
    width: 240px;
  }
  .timeBlock {
    display: flex;
    gap: 4px;
  }
}
