.container {
   display: flex;
   flex-direction: column;
   gap: 120px;
}
@media  (max-width: 768px){
   .container {
      gap: 96px;
   }
}
@media (max-width: 320px) {
   .container {
      gap: 64px;
   }
}