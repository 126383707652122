.wrap {
  display: flex;
  flex-direction: column;
  gap: 64px;
  max-width: 100vw;
}

.darkBlock {
  background: #1d1e2c;
  display: flex;
  flex-direction: column;
  text-align: start;
  padding: 72px 0;
  position: relative;
  overflow: hidden;
}

.whiteBlock {
  display: flex;
  flex-direction: column;
  gap: 120px;
}

.container {
  margin: 0 auto;
  width: 1216px;
  text-align: start;
  transform: translateY(22%);
  animation: ani 1.5s forwards;
}

@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  0% {
    transform: translateY(22%);
  }
  100% {
    transform: translateX(0);
  }
}

.logo {
  position: absolute;
  right: -100px;
  bottom: 68px;
  transform: rotate(0.376deg);
  mix-blend-mode: overlay;
  filter: blur(2.5px);
}

.title {
  color: #fcfcfc;
  font-family: Kanit;
  font-size: 61px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.headLineDescription {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
}

.headLineText {
  font-family: Karla;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #fcfcfc;
  max-width: 800px;
  opacity: 0.9;
}

@media (max-width: 1330px) {
  .logo svg {
    width: 420px;
  }
  .container {
    max-width: 90vw;
  }
}

@media (max-width: 1330px) {
  .logo svg {
    width: 450px;
  }
  .container {
    max-width: 90vw;
  }
}

@media (max-width: 1200px) {
  .hideImage {
    display: none;
  }
}

@media (max-width: 768px) {
  .wrap {
    gap: 56px;
  }

  .darkBlock {
    padding: 56px 0;
  }

  .whiteBlock {
    gap: 96px;
  }

  .headLineText {
    max-width: 672px;
  }
}

@media (max-width: 500px) {
  .container {
    transform: none;
    animation: none;
  }
  .wrap {
    gap: 40px;
  }

  .darkBlock {
    padding:88px 0 40px 0;
  }

  .whiteBlock {
    gap: 64px;
  }
  .title {
    font-size: 39px;
    font-weight: 500;
    line-height: 40px;
  }

  .headLineDescription {
    gap: 12px;
    margin-bottom: 24px;
  }

  .headLineText {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.08px;
  }
}
