.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 488px;
}
.topButtonContainer {
  display: flex;
  gap: 16px;
}

.buttonBig {
  width: 408px;
}

.button {
  width: 155px;
}

@media (max-width: 600px) {
  .buttons {
    gap: 12px;
    width: 100%;
    margin: auto;
  }
  .topButtonContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .buttonBig,
  .button {
    box-sizing: border-box;
    width: 100%;
    height: 48px;
  }
  .buttonBig {
    padding: 12px 16px;
  }
}
