.wrap {
  width: 100vw;
  background-color: #1d1e2c;
  padding: 8px 0;
  position: relative;
}

.container {
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  max-width: 1216px;
}

.containerLogo {
  cursor: pointer;
}

.containerSidebar {
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
}

.categories {
  text-decoration: none;
  font-family: Karla;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #fcfcfc;
  transition: all 0.5s;
}

.categories:active {
  color: #fcfcfc;
  border-bottom: 2px solid #5b7cfe;
}

.activeСategories {
  color: #fcfcfc;
  border-bottom: 2px solid #5b7cfe;
}

.containerButtons {
  display: flex;
  gap: 32px;
}

.button {
  width: 96px;
}
.burger,
.logoMobile {
  display: none;
}

@media (max-width: 1330px) {
  .container {
    max-width: 90vw;
  }
}

@media (max-width: 1200px) {
  .containerSidebar {
    gap: 16px;
  }
  .categories {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
    text-align: center;
  }
  .containerButtons {
    gap: 8px;
  }
  .containerLogo {
    width: 45px;
    height: 40px;
  }
  .containerLogo svg {
    width: 45px;
    height: 40px;
  }
}


@media (max-width: 768px) {
  .categories {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
    text-align: center;
  }
}

@media (max-width: 700px) {
  .wrap {
    display:flex;
    position:fixed;
    top:0;
    right:0;
    left:0;
    z-index: 9999;
  }
  .container {
    flex:1;
    margin: 0;
    padding: 0 16px;
  }
  .hideSidebar,
  .hideBtn {
    display: none;
  }
  .containerLogo {
    width: 37px;
    height: 32px;
  }
  .containerLogo svg {
    width: 37px;
    height: 32px;
  }
  .burger {
    width: 32px;
    height: 32px;
  }
  .burger,
  .logoMobile {
    display: block;
  }
  .menu {
    position: absolute;
    left: 0;
    right: 0;
    top: 48px;
    height: calc(100vh - 130px);
    background-color: #fcfcfc;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 16px 32px 16px;
    max-width: 288px;
  }
  .openMenu {
    background-color: #fcfcfc;
    box-shadow: 0 1px 0 0 #b9c7ff29;
  }
  .categoriesMobile {
    text-decoration: none;
    font-family: Karla;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: start;
    transition: all 0.5s;
    color: #1d1e2c;
    height: 40px;
    display: flex;
    align-items: center;
  }
  .containerButtonsBurger {
    max-width: 288px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .button {
    box-sizing: border-box;
    width: 100%;
    color: #1d1e2c;
  }
}
