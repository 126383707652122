.wrap {
  width: 100vw;
  background-color: #1d1e2c;
  margin-top: 120px;
}

.container {
  max-width: 1216px;
  margin: 0 auto;
  padding-bottom: 72px;
}

.vidget {
  position: absolute;
  width: 320px;
  bottom: 200px;
  z-index: 30;
}

@media (max-width: 1330px) {
  .container {
    max-width: 90vw;
  }
}

@media (max-width: 768px) {
  .container {
    padding:40px 16px 64px 16px;
  }

  .wrap {
    margin-top: 96px;
  }
}
@media (max-width: 500px) {
  .wrap {
    margin-top: 64px;
  }
  .container {
    padding:56px 16px;
  }
}
@media (max-width: 321px) {
  .container {
    padding-bottom: 56px;
  }

  .vidget {
    width: 280px;
  }
}
