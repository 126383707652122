.wrap {
  max-width: 90vw;
  margin: 0 auto;
  margin-bottom: 40px;
  margin-top: 64px;
}

.container {
  margin: 0 auto;
  max-width: 1216px;
  display: flex;
  gap: 20px;
  opacity: 0;
  transform: translateY(22%);
  animation: ani 1.5s forwards;
}
@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  0% {
    transform: translateY(22%);
  }
  100% {
    transform: translateX(0);
  }
}

.filterContainer {
  display: flex;
  gap: 20px;
}

.searchContainer {
  position: relative;
}

.searchIcon {
  position: absolute;
  top: 12px;
  left: 20px;
}

.checkPicker {
  width: 256px;
  height: 48px;
}

.dropdownMenu {
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
  color: #1d1e2c;
}
.filtersBurger {
  display: none;
}

@media (max-width: 1330px) {
  .container {
    max-width: 90vw;
  }
}

@media (max-width: 1200px) {
  .wrap {
    margin-bottom: 32px;
    margin-top: 56px;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .filterContainer {
    display: flex;
    gap: 8px;
  }
  .checkPicker {
    width: 100%;
    height: 48px;
  }
  .tabletInput {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .wrap {
    margin-top: 40px;
  }
  .hideFilters {
    display: none;
  }
  .mobileInput {
    max-width: 100%;
  }
  .container {
    flex-direction: row;
    gap: 8px;
  }
  .filtersBurger {
    width: 48px;
    height: 48px;
    border-radius: 1000px;
    background: #1d1e2c0a;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .blueColor {
    background: #5b7cfe;
  }
  .searchContainer {
    flex:1
  }
}
