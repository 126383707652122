.wrap {
  width: 100vw;
  background: #5b7cfe;
  position: relative;
}

.logo {
  position: absolute;
  bottom: 71px;
}
.logoMobile {
  display: none;
}

.container {
  max-width: 1216px;
  margin: 0 auto;
  padding: 56px 0 27px;
  text-align: start;
}

.containerTop {
  padding-right: 69px;
  padding-bottom: 75px;
  border-bottom: 1px solid #99adfe;
  display: flex;
  justify-content: space-between;
}

.topLinks {
  display: flex;
  gap: 184px;
}

.containerTopLinks {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  color: #fcfcfc;
  font-family: Kanit;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.text {
  color: #d6deff;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.08px;
}

.icons {
  display: flex;
  gap: 16px;
}

.links {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.link {
  cursor: pointer;
  text-decoration: none;
  color: #d6deff;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.08px;
  scroll-behavior: smooth;
}

.containerBottom {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.bottomLinks {
  display: flex;
  gap: 32px;
}

@media (max-width: 1330px) {
  .container {
    max-width: 90vw;
  }
}

@media (max-width: 850px) {
  .logoContainer svg {
    width: 56px;
    height: 80px;
  }

  .containerTop {
    display: flex;
    gap: 120px;
  }
  .topLinks {
    gap: 136px;
  }
}

@media (max-width: 700px) {
  .topLinks,
  .containerBottom {
    gap: 30px;
  }

  .containerTop {
    padding-right: 20px;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 40px 16px 24px;
  }
  .containerTop {
    display: flex;
    flex-direction: row-reverse;
    gap: 44px;
    padding-right: 0;
    padding-bottom: 40px;
  }

  .bottomLinks {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .containerBottom {
    padding-top: 32px;
    display: flex;
    gap: 24px;
    flex-direction: column-reverse;
  }
  .topLinks {
    display: flex;
    flex-direction: column-reverse;
    gap: 32px;
  }
  .containerTopLinks {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .link {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
  }
  .logoContainer svg {
    width: 60px;
    height: 86px;
  }
  .logo {
    display: none;
  }
  .logoMobile {
    display: block;
    position: absolute;
    top: 40px;
    right: 0;
  }
  .logoMobile g {
    mix-blend-mode: overlay;
  }
  .icons {
    margin-top:24px;
  }
}
