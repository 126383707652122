.inputType {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #fcfcfc;
  height: 41px;
  width: 660px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #fcfcfc;
  padding-left: 16px;
  margin-bottom: 12px;
  box-sizing: border-box;
}

.inputType::placeholder {
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: rgba(252, 252, 252, 0.64);
}

.inputType:hover {
  border-bottom: 1px solid #5b7cfe;
}

.inputType:focus {
  outline: none;
}

:focus::-webkit-input-placeholder {
  color: transparent;
}

:focus::-moz-placeholder {
  color: transparent;
}

:focus:-moz-placeholder {
  color: transparent;
}

:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}
input::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}
input:-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}
input:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}
input:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
input:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
input:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
input:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.error {
  border-bottom: 1px solid #ff3a20;
}

.error:hover {
  border-bottom: 1px solid #ff3a20;
}

.textError {
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ff3a20;
  display: flex;
  justify-content: end;
  gap: 5.6px;
}

.searchType {
  background: rgba(29, 30, 44, 0.04);
  border-radius: 1000px;
  border: 1px solid transparent;
  width: 392px;
  height: 48px;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.08px;
  color: #000000;
  padding: 12px 20px 12px 52px;
  box-sizing: border-box;
}

@media (max-width: 1330px) {
  .searchType {
    width: 256px;
  }
}

@media (max-width: 1150px) {
  .searchType {
    width: 100%;
  }
}

.searchType::placeholder {
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.08px;
  color: #9a9a9a;
  display: flex;
  align-items: end;
}

.searchType:hover {
  border: 1px solid #5b7cfe;
}

.searchType:focus {
  outline: none;
  border: 1px solid #5b7cfe;
  box-shadow: 0px 0px 10px rgba(91, 124, 254, 0.25);
}
