.container {
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #0057b8;
  display: flex;
  gap: 4px;
}

.ukraine {
   color: #FFD700;
}
@media (max-width: 768px) {
  .container {
    letter-spacing: 0;
  }
}
@media (max-width: 320px) {
  .container {
    font-size: 12px;
    line-height: 16px;
  }
  .icon {
    width:16px;
    height: 16px;
  }
  .icon svg{
    width:16px;
    height: 16px;
  }
}
