.container {
  width: 280px;
  padding: 32px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 20px;
  background-color: #5b7cfe;
}

.containerTab {
  display: flex;
  padding: 8px 40px;
  justify-content: center;
  align-items: center;
  color: #fcfcfc;
  text-align: center;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.08px;
  cursor: pointer;
}

.clicked {
   border-radius: 100px;
   border: 1px solid #FCFCFC;
   
}
@media (max-width: 768px) {
  .container {
    width: 232px;
    padding: 24px 35px;
  }
}