.wrap {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.darkBlock {
  background: #1d1e2c;
  padding: 72px;
  position: relative;
}

.whiteBlock {
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 1216px;
  margin: 0 auto;
}

.headline {
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  transform: translateY(22%);
  animation: ani 1.5s forwards;
}
@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  0% {
    transform: translateY(22%);
  }
  100% {
    transform: translateX(0);
  }
}

.headlineImage {
  position: absolute;
  right: 16px;
  top: 88px;
}

.headlineDesc {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.headlineTitle {
  color: #fcfcfc;
  font-family: Kanit;
  font-size: 61px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px;
  text-transform: uppercase;
}

.headlineText {
  max-width: 592px;
  color: #fcfcfc;
  font-family: Karla;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
}

.button {
  width: 200px;
}

.containerPlatforms {
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 90vw;
}

.platforms {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: start;
}

.platformsTitle {
  color: #5b7cfe;
  font-family: Kanit;
  font-size: 39px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.78px;
  opacity: 0.9;
}

.platformsSubtitle {
  max-width: 723px;
  color: #1d1e2c;
  font-family: Karla;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
  opacity: 0.9;
}

.stepsGroup {
  display: flex;
  flex-direction: column;
  gap: 48px;
  text-align: start;
}

.step {
  color: #1d1e2c;
  font-family: Karla;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
  position: relative;
  padding-left: 22px;
}

.stepsEllipse {
  position: absolute;
  left: 0;
  top: -8px;
  z-index: -1;
}

@media (max-width: 1330px) {
  .headlineImage svg {
    width: 500px;
  }

  .headlineImage {
    top: 50px;
  }
  .container {
    max-width: 90vw;
  }
}

@media (max-width: 1200px) {
  .hideImage {
    display: none;
  }
  .darkBlock {
    padding: 50px;
  }
}

@media (max-width: 860px) {
  .darkBlock {
    padding: 56px 0;
  }
  .platformsSubtitle {
    max-width: 584px;
  }
  .stepsGroup {
    gap: 43px;
  }
}

@media (max-width: 768px) {
  .wrap {
    gap: 56px;
  }
  .headlineTitle .headlineText {
    width: 672px;
  }
}

@media (max-width: 700px) {
  .darkBlock {
    padding:88px 16px 40px 16px;
  }

  .headlineTitle {
    font-size: 39px;
    font-weight: 500;
    line-height: 40px;
  }

  .headlineText {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.08px;
  }
  .button {
    width: auto;
  }

  .containerPlatforms {
    gap: 32px;
  }

  .platforms {
    gap: 8px;
  }

  .platformsTitle {
    font-size: 30px;
    line-height: 32px;
    letter-spacing: 0.6px;
  }

  .platformsSubtitle .step {
    font-size: 16px;
    letter-spacing: 0.08px;
  }

  .stepsGroup {
    gap: 32px;
  }
}

@media (max-width: 500px) {
  .headline {
    transform: none;
    animation: none;
  }
  .wrap {
    gap:40px;
  }
  .platformsSubtitle,.step {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
  }
  .headline {
    gap: 24px;
  }
}
