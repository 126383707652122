.filterContainer {
    display: none;
    margin-top: 16px;
    flex-direction: column;
    gap:12px;
}
.hideFilters {
    display:none;
}
.checkPicker {
    width: 100%;
    height: 48px;
}
.dropdownMenu {
    font-family: Karla;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    color: #1d1e2c;
}
@media (max-width: 700px){
    .filterContainer {
        display: flex;
    }
}