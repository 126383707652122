.wrap {
  width: 100vw;
}

.container {
  max-width: 1216px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0 auto;
  gap: 56px;
}

.name {
  font-family: Kanit;
  font-size: 39px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.78px;
  color: #1d1e2c;
  text-align: start;
  padding-bottom: 8px;
  width: max-content;
  border-bottom: 4px solid #5b7cfe;
}
.containerBenefits {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.containerBenefit {
  width: 280px;
  height: 280px;
  border-radius: 20px;
  background-color: #1d1e2c;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 72px;
  align-items: center;
  gap: 8px;
}

.title {
  font-family: Kanit;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: #fcfcfc;
}
.subtitle {
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.08px;
  color: #fcfcfc;
  max-width: 232px;
}

.blueText {
  color: #5b7cfe;
}

.darkContainerBenefit {
  position: relative;
}

.blueLine {
  position: absolute;
  bottom: -5px;
  right: 0;
}

.blueContainerBenefit {
  position: relative;
  background: #5b7cfe;
}

.whiteContainerBenefit {
  background: none;
  border: 3px solid #5b7cfe;
  position: relative;
}

.whiteLines {
  position: absolute;
  right: 0;
  bottom: -5px;
}

.blueLines {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -10;
}

@media (max-width: 1330px) {
  .container {
    max-width: 90vw;
  }
  .containerBenefits {
    gap: 16px;
  }
}

@media (max-width: 1050px) {
  .container {
    gap: 32px;
    justify-content: center;
  }
  .containerBenefits {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    justify-content: center;
  }
  .containerBenefit {
    width: 320px;
    height: 320px;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;
  }
}



@media (max-width: 520px) {
  .container {
    gap: 24px;
    align-items: center;
  }

  .containerBenefits {
    align-items: center;
    gap:24px;
  }
  .containerBenefit {
    width:100%
  }
}
