.wrap {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.darkBlock {
  background: #1d1e2c;
  display: flex;
  text-align: start;
}

.whiteBlock {
  display: flex;
  flex-direction: column;
  gap: 120px;
  text-align: start;
}

.container {
  width: 1216px;
  margin: 0 auto;
}

.headline {
  padding: 72px 0;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transform: translateY(22%);
  animation: ani 1.5s forwards;
}
@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  0% {
    transform: translateY(22%);
  }
  100% {
    transform: translateX(0);
  }
}

.about {
  display: flex;
  flex-direction: column;
  gap: 72px;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  color: #fcfcfc;
  font-family: Kanit;
  font-size: 61px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px;
  text-transform: uppercase;
}

.containerDescription {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 592px;
}

.description {
  color: #fcfcfc;
  font-family: Karla;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
}

.button {
  width: max-content;
  font-size: 16px;
}

.fullTeam {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 386px;
}

.team {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.teamTitle {
  color: #fcfcfc;
  font-family: Kanit;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.teamStructure {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 4px;
}

.area {
  border-radius: 100px;
  border: 1px solid #5b7cfe;
  width: 190px;
  color: #fcfcfc;
  text-align: center;
  font-family: Karla;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 8px 0;
  box-sizing: border-box;
}

.bigSize {
  width: 384px;
  box-sizing: border-box;
}

.teamDescription {
  color: #fcfcfc;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.08px;
  max-width: 373px;
}

.stages {
  display: flex;
  justify-content: space-between;
}

.stage {
  border-radius: 20px;
  box-shadow: 0 4px 10px 0 rgba(175, 175, 175, 0.25);
  width: 384px;
  height: 216px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.stageSVG {
  position: absolute;
  right: 0;
  bottom: -4px;
}
.stageTitle {
  color: #5b7cfe;
  font-family: Kanit;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.stageDesk {
  color: #1d1e2c;
  max-width: 320px;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.08px;
}

.containerSteps {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.containerTitle {
  color: #1d1e2c;
  font-family: Kanit;
  font-size: 39px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.78px;
  padding-bottom: 8px;
  border-bottom: 4px solid #5b7cfe;
  width: max-content;
}

.containerStepsGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.stepsGroup {
  display: flex;
  flex-direction: column;
  gap: 51px;
}

.step {
  max-width: 776px;
  color: #1d1e2c;
  font-family: Kanit;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  position: relative;
  padding-left: 22px;
}

.stepsEllipse {
  position: absolute;
  left: 0;
  top: -8px;
  z-index: -1;
}

.stepsComment {
  max-width: 574px;
  color: #9a9a9a;
  font-family: Karla;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
  padding-bottom: 4px;
  border-bottom: 1px solid #9a9a9a;
  margin: 0 0 0 auto;
}

.containerFutures {
  display: flex;
  flex-direction: column;
  gap: 56px;
}

.futuresCards {
  display: flex;
  justify-content: space-between;
}

.card {
  width: 384px;
  height: 500px;
  border-radius: 20px;
  background-color: #5b7cfe;
  position: relative;
  overflow: hidden;
  padding: 32px;
  box-sizing: border-box;
}

.whiteCard {
  background-color: #fcfcfc;
  box-shadow: 0px 4px 10px 0px rgba(175, 175, 175, 0.25);
}

.futureOne {
  position: absolute;
  right: 32px;
  bottom: 52px;
}

.futureTwo{
  position: absolute;
  left: 32px;
  top: 32px;
}

.futureTwoLine {
  position: absolute;
  right: 0;
  bottom: -3px;
}

.futureThree{
  position: absolute;
  top: 0;
  right: 0;
}

.futureTitle {
  color: #FCFCFC;
  font-family: Kanit;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.futureTitleDark {
  color: #1D1E2C;
  max-width: 280px;
  text-align: end;
  margin: 0 0 0 auto;
}

.futureDesc {
  color: #1D1E2C;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.08px;
}

.futureDescDark {
  color: #5B7CFE;
}

.futureOneText {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.futureTwoText {
  margin-top: 96px;
  display: flex;
  flex-direction: column;
  gap: 88px;
}

.futureThreeText {
  margin-top: 265px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.laptop {
  display: none;
}
.futureTwoLineMobile {
 display: none;
}
.futureThreeMobile {
  display:none;
}

@media (max-width: 1330px) {
  .container {
    max-width: 90vw;
  }
  .stages {
    gap:10px;
  }
  .futuresCards {
    gap:10px;
  }
}
@media (max-width: 1120px) {
  .headline {
    padding: 56px 0;
  }
  .hideBlock {
    display:none
  }
  .laptop {
    display: flex;
    max-width: 100%;
    gap:24px;
  }
  .teamTitle {
    font-size: 25px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0;
    text-align: left;
    color: #1D1E2C;
  }
  .teamStructure {
    gap:8px;
  }
  .area {
    width: auto;
    height: 32px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
    text-align: center;
    padding:8px 40px;
    box-sizing: border-box;
    color: #1D1E2C;
    display: flex;
  }
  .bigSize {
    width:auto;
    box-sizing: border-box;
  }
  .teamDescription {
    color: #1D1E2C;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
    max-width: 100%;
  }
  .whiteBlock {
    gap: 96px;
  }
  .stages {
    flex-direction: column;
    gap:32px;
    margin-bottom: 16px;
  }
  .rightSideBlock {
    align-self: flex-end;
  }
  .stepsComment {
    padding: 0 23px;
    max-width: 100%;
    display:flex;
    text-align: center;
    margin-bottom: 48px;
    border-bottom:none;
  }
  .containerFutures {
    gap: 32px;
  }
  .futuresCards {
    display: flex;
    flex-direction: column;
    gap:44px;
  }
}
@media (max-width:700px ) {

  .title {
    font-size: 39px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0;
    text-align: left;
  }
  .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
  }
  .about {
    gap: 24px;
    padding-top:40px ;
  }
  .headline {
    padding: 40px 0;
  }
  .wrap {
    gap: 40px;
  }
  .whiteBlock {
    gap: 64px;
    text-align: center;
  }
  .stage {
    border-radius: 20px;
    box-shadow: 0 4px 10px 0 rgba(175, 175, 175, 0.25);
    width: auto;
    height: auto;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
  }
  .containerTitle {
    font-size: 30px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-align: center;
    width: auto;
  }
  .step {
    max-width: 776px;
    color: #1d1e2c;
    font-style: normal;
    font-weight: 400;
    position: relative;
    padding-left: 22px;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
  }
  .stepsComment {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;
    padding:0;
    margin-bottom: 0;
    border-bottom: none;
  }
  .stepsGroup {
    gap: 24px;
  }
  .containerStepsGroup {
    gap: 24px;
  }
  .containerFutures {
    gap: 24px;
    align-items: center;
  }
  .card {
    width: 288px;
    height: 456px;
    border-radius: 20px;
    padding: 24px 16px;
    box-sizing: border-box;
  }
  .futureOne {
    bottom: 32px;
  }
  .futureOne svg{
    width: 56px;
    height: 56px;
  }
  .futureTwo{
    position: absolute;
    left: 16px;
    top: 370px;
  }
  .futureTwoLine {
    display: none;
  }
  .futureTwoLineMobile {
    display: block;
    position: absolute;
    right: 0;
    bottom: -3px;
  }
  .futureTwoText {
   margin-top: 0;
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
  }
  .futureTitleDark {
    text-align: center;
  }
  .futureThree{
    display:none;
  }
  .futureThreeText {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .futureThreeMobile {
    position: absolute;
    bottom: -6px;
    right: 0;
  }
  .futuresCards {
    gap:16px;
  }
  .stages {
    gap:16px;
  }
  .containerSteps {
    gap: 34px;
  }
  .button {
    width:auto;
  }
}
@media (max-width: 500px) {
  .headline {
    opacity: 1;
    transform: none;
    animation: none;
  }
  .teamStructure {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .rightSideBlock {
    align-self: auto;
  }
  .area {
    justify-content: center;
  }
  .stages {
    gap:16px;
    align-items: center;
  }
}