.container {
  margin: 0 auto;
  width: 1216px;
  display: flex;
  flex-direction: column;
  gap: 56px;
}
.verticalTimeline {
  width: 100% !important;
  padding-top: 4em;
  padding-bottom: 8em;
  max-width: 1216px;
}
.verticalTimeline::before {
  width: 6px;
  background: #5b7cfe;
}

.point {
  margin-top: 62px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: start;
}

.title {
  color: #5b7cfe;
  font-family: Kanit;
  font-size: 39px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.78px;
}

.data {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 592px;
  padding-left: 0;
  margin: 0;
  list-style: initial;
}

.description {
  color: #1d1e2c;
  font-family: Kanit;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding-left: 0;
}

.description::before {
  content: "";
  margin-left: 0;
}

.vertical-timeline-element-content {
  border-radius: 0 !important;
}

.containerTitle {
  color: #1d1e2c;
  font-family: Kanit;
  font-size: 39px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.78px;
  padding-bottom: 8px;
  border-bottom: 4px solid #5b7cfe;
  width: max-content;
}

.eclipse {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.eclipse svg {
  width: 56px;
  height: 56px;
  margin-left: -26.5px;
}

@media (max-width: 1330px) {
  .container {
    max-width: 90vw;
  }

  .verticalTimeline {
    width: 100% !important;
    padding-top: 4em;
    padding-bottom: 8em;
    max-width: 1216px;
  }
}
@media (min-width: 600px) and (max-width: 1330px) {
  .verticalTimeline::before {
    left: 50%;
  }
  .verticalTimelineElementWork > div {
    width: 44%;
    margin-left: 0;
  }
  .eclipse {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -28px;
  }
}

@media (max-width: 768px) {
  .container {
    gap: 32px;
  }
  .data {
    gap: 24px;
  }
}
@media (max-width: 500px) {
  .container {
    align-items: center;
  }
}
@media (max-width: 400px) {
  .container {
    gap: 24px;
  }

  .verticalTimeline::before {
    width: 4px;
  }

  .point {
    gap: 16px;
  }

  .title {
    font-size: 30px;
    line-height: 32px;
    letter-spacing: 0.6px;
  }

  .data {
    gap: 8px;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.08px;
  }

  .containerTitle {
    font-size: 30px;
    line-height: 32px;
    letter-spacing: 0.6px;
    text-align: center;
    margin: 0 auto;
  }
  .eclipse svg {
    width: 48px;
    height: 48px;
    margin-left: -23px;
  }
}
