.container {
  max-width: 1216px;
  margin: 0 auto;
}

.darkBlock {
  background: #1d1e2c;
  padding: 72px 0;
  position: relative;
}

.headlineImage {
  position: absolute;
  right: 0;
  top: 64px;
}

.headline {
  text-align: start;
  transform: translateY(22%);
  animation: ani 1.5s forwards;
}

@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  0% {
    transform: translateY(22%);
  }
  100% {
    transform: translateX(0);
  }
}

.headlineDesc {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 624px;
}

.headlineTitle {
  color: #fcfcfc;
  font-family: Kanit;
  font-size: 61px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px;
  text-transform: uppercase;
}

.headlineText {
  color: #fcfcfc;
  font-family: Karla;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 20px;
}

@media (max-width: 1330px) {
  .headlineImage svg {
    width: 500px;
  }

  .headlineImage {
    top: 50px;
  }
  .container {
    max-width: 90vw;
  }
}

@media (max-width: 1100px) {
  .hideImage {
    display: none;
  }
  .darkBlock {
    padding: 50px;
  }
}

@media (max-width: 768px) {
  .darkBlock {
    padding: 48px 0;
  }
  .headlineDesc {
    max-width: 100%;
  }
  .headlineText {
    gap: 16px;
  }
}

@media (max-width: 500px) {
  .headline {
    text-align: start;
    transform: none;
    animation: none;
  }
  .darkBlock {
    padding: 88px 0 16px 0;
  }

  .headlineTitle {
    font-size: 39px;
    font-weight: 500;
    line-height: 40px;
  }

  .headlineText {
    gap: 8px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.08px;
  }
}
