.wrap {
  width: 100vw;
}

.container {
  margin: 0 auto;
  max-width: 1216px;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 56px;
}

.title {
  font-family: Kanit;
  font-size: 39px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.78px;
  color: #1d1e2c;
  text-align: start;
  padding-bottom: 8px;
  width: max-content;
  border-bottom: 4px solid #5b7cfe;
}

.tabslist {
  display: flex;
  gap: 32px;
}

.icons {
  width: 904px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 32px;
  column-gap: 40px;
  justify-content: center;
  align-content: center;
  align-items: center;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  align-content: center;
}

@media (max-width: 1330px) {
  .container {
    max-width: 90vw;
  }
}

@media (max-width: 768px) {
  .container {
    gap: 32px;
  }
  .icons svg {
    width: 32px;
    height: 32px;
  }
}
@media (max-width: 500px) {
  .container {
    align-items: center;
    gap: 24px;
  }
  .tabslist {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }
  .icons {
    max-width: 288px;
    gap: 24px;
  }
}
