.wrap {
  width: 100vw;
  background-color: #1d1e2c;
  padding: 56px 0 80px;
  position: relative;
}

.container {
  position: relative;
  margin: 0 auto;
  max-width: 1216px;
  opacity: 0;
  transform: translateY(22%);
  animation: ani 1.5s forwards;
}
@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  0% {
    transform: translateY(22%);
  }
  100% {
    transform: translateX(0);
  }
}

.ukraine {
  margin-bottom: 8px;
}

.containerTop {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 56px;
}

.containerBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-family: Kanit;
  font-size: 61px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px;
  text-transform: uppercase;
  color: #fcfcfc;
  text-align: left;
  max-width: 701px;
  margin-bottom: 24px;
}

.titleViolet {
  color: #5b7cfe;
}

.subTitle {
  font-family: Karla;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
  opacity: 0.8;
  text-align: start;
  max-width: 598px;
  color: #fcfcfc;
}

.image {
  position: absolute;
  right: 0;
  top: 0;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 488px;
}
.topButtonContainer {
  display: flex;
  gap: 16px;
}

.buttonBig {
  width: 408px;
}

.button {
  width: 155px;
}

.line {
  position: absolute;
  right: 0;
  bottom: 100px;
}

@media (max-width: 1330px) {
  .image svg {
    width: 300px;
  }

  .line svg {
    width: 500px;
  }
  .container {
    max-width: 90vw;
  }
}

@media (max-width: 1130px) {
  .hideImage svg {
    display: none;
  }
}

@media (max-width: 768px) {
  .wrap {
    padding: 48px 0;
  }

  .containerTop {
    margin-bottom: 52px;
  }

  .title {
    max-width: 672px;
  }

  .subTitle {
    max-width: 672px;
  }
}

@media (max-width: 700px) {
  .wrap {
    padding: 80px 0 40px 0;
  }
  .hideUkraine {
    display: none;
  }
}

@media (max-width: 600px) {
  .container {
    transform: none;
    opacity: 1;
    animation: none;
  }
  .title {
    font-size: 39px;
    line-height: 40px;
  }
  .subTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
  }
  .containerTop {
    margin-bottom: 24px;
  }
  .containerBottom {
    flex-direction: column;
  }
}
